// sagas/index.js
import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import donorSaga from './donorSaga';
import userSaga from './userSaga';
import textChatSaga from './textChatSaga';
import emailSaga from "./emailSaga";
import globalSaga from './globalSaga';
import videoChatSaga from './videoChatSaga';
import modalSaga from './modalSaga';
import blogSaga from './blogSaga';

function* rootSaga() {
  yield all([
    authSaga(),
    donorSaga(),
    userSaga(),
    textChatSaga(),
    emailSaga(),
    globalSaga(),
    videoChatSaga(),
    modalSaga(),
    blogSaga()
  ]);
}

export default rootSaga;
