// import SingleBlogPage from '../pages/SingleBlogPage';

const MainPage = () => import('../pages/MainPage');
const CloudPage = () => import('../pages/CloudPage');
const PageLoader = () => import('../pages/CloudPage/components/PageLoader');
const BlogPage = () => import('../pages/BlogPage');
const SingleBlogPage = () => import('../pages/SingleBlogPage');
const ClientProfilePage = () => import('../pages/ClientProfilePage');
const ComparedDonorsPage = () => import('../pages/ComparedDonorsPage');
const DonorProgramsPage = () => import('../pages/DonorProgramsPage');
const DonorRegistrationPage = () => import('../pages/DonorRegistrationPage');
const DonorRequestPage = () => import('../pages/DonorRequestPage');
const DonorsGalery = () => import('../pages/DonorsGalery');
const LikedDonorsPage = () => import('../pages/LikedDonorsPage');
const Mailbox = () => import('../pages/Mailbox');
const NotFoundPage = () => import('../pages/NotFoundPage');
const PackagesPage = () => import('../pages/PackagesPage');
const Register = () => import('../pages/Register');
const SavedDonorsPage = () => import('../pages/SavedDonorsPage');
const SettingsPage = () => import('../pages/SettingsPage');
// const Post1 = () => import('../pages/SingleBlogPage/components/Post-1');
// const Post2 = () => import('../pages/SingleBlogPage/components/Post-2');
const Post3 = () => import('../pages/SingleBlogPage/components/Post-3');
const SingleDonorPage = () => import('../pages/SingleDonorPage');
const SingleProgramPage = () => import('../pages/SingleProgramPage');
const AboutUsPage = () => import('../pages/StaticPages/components/AboutUsPage');
const ContactUsPage = () => import('../pages/StaticPages/components/ContactUsPage');
const EggDonorsTripsPage = () => import('../pages/StaticPages/components/EggDonorsTripsPage');
const LegislationPage = () => import('../pages/StaticPages/components/LegislationPage/LegislationPage');
const PrivacyPolicyPage = () => import('../pages/StaticPages/components/PrivacyPolicyPage');
const ProgramStagesPage = () => import('../pages/StaticPages/components/ProgramStagesPage');
const QuickStart = () => import('../pages/StaticPages/components/QuickStart');
const SurrogateTripsPage = () => import('../pages/StaticPages/components/SurrogateTripsPage');
const TermsAndConditions = () => import('../pages/StaticPages/components/TermsAndConditions');
const TripToChildbirthPage = () => import('../pages/StaticPages/components/TripToChildbirthPage/TripToChildbirthPage');
const TripsToClinicPage = () => import('../pages/StaticPages/components/TripsToClinicPage');
const Textchat = () => import('../pages/Textchat');
const VideoChat = () => import('../pages/VideoChat');

export {
  CloudPage,
  PageLoader,
  BlogPage,
  SingleBlogPage,
  ClientProfilePage,
  ComparedDonorsPage,
  DonorProgramsPage,
  DonorRegistrationPage,
  DonorRequestPage,
  DonorsGalery,
  LikedDonorsPage,
  Mailbox,
  NotFoundPage,
  PackagesPage,
  Register,
  SavedDonorsPage,
  SettingsPage,
//   Post1,
//   Post2,
  Post3,
  SingleDonorPage,
  SingleProgramPage,
  AboutUsPage,
  ContactUsPage,
  EggDonorsTripsPage,
  LegislationPage,
  PrivacyPolicyPage,
  ProgramStagesPage,
  QuickStart,
  SurrogateTripsPage,
  TermsAndConditions,
  TripToChildbirthPage,
  TripsToClinicPage,
  Textchat,
  VideoChat
};

export const routes = [
    {
        path: '/',
        component: MainPage,
        seo: {
            title: 'Surrogacy Platform: Find Egg Donors and Surrogate Mother',
            description: 'Surrogacy platform - a service with an open database of egg donors and surrogate mothers. List of egg donors and surrogate mothers with pictures',
        },
    },
    {
        path: '/best-map',
        component: CloudPage,
        seo: {
            title: 'Surrogacy Platform: Find Egg Donors and Surrogate Mother',
            description: 'Surrogacy platform - a service with an open database of egg donors and surrogate mothers. List of egg donors and surrogate mothers with pictures',
        },
    },
    {
        path: '/:pageUrl',
        component: PageLoader,
        seo: {
          title: '',
          description: '',
        },
      },
    {
        path: '/login',
        component: Register,
        seo: {
            title: 'Login',
            description: 'Log in to your account.',
        },
    },
    {
        path: '/videochat',
        component: VideoChat,
        seo: {
            title: 'Video Chat',
            description: 'Start a video chat.',
        },
    },
    {
        path: '/videochat/:chatId',
        component: VideoChat,
        seo: {
            title: 'Video Chat Room',
            description: 'Join a specific video chat room.',
        },
    },
    {
        path: '/videochat/:chatId/:roomId',
        component: VideoChat,
        seo: {
            title: 'Video Chat Room',
            description: 'Join a video chat room with a specific ID.',
        },
    },
    {
        path: '/donors',
        component: DonorsGalery,
        seo: {
            title: 'Egg Donors Free Database with Pictures',
            description: 'Egg Donor Catalog with Photo and Live Chat. Find your Egg Donor in Free Open Database and Talk in Video Chat or Mail',
        },
    },
    {
        path: '/donors/:donorsType',
        component: DonorsGalery,
        seo: {
            title: 'Filtered Donors',
            description: 'Browse filtered donors by type.',
        },
    },
    {
        path: '/donors/page/:pageNumber',
        component: DonorsGalery,
        seo: {
            title: 'Egg Donors Catalog with Pictures and Live Chat',
            description: 'Egg Donors Pictures list. List of Egg Donors with Free Photo and Video Chat. Find your Donor and start Talk Now!',
        },
    },
    {
        path: '/donor/:donorParams',
        component: SingleDonorPage,
        seo: {
            title: 'Donor Details',
            description: 'View details of a specific donor.',
        },
    },
    {
        path: '/textchat',
        component: Textchat,
        seo: {
            title: 'Text Chat',
            description: 'Join a text chat.',
        },
    },
    {
        path: '/textchat/:chatId',
        component: Textchat,
        seo: {
            title: 'Text Chat Room',
            description: 'Join a specific text chat room.',
        },
    },
    {
        path: '/mailbox',
        component: Mailbox,
        seo: {
            title: 'Mailbox',
            description: 'Access your mailbox.',
        },
    },
    {
        path: '/mailbox/:box',
        component: Mailbox,
        seo: {
            title: 'Mailbox Folder',
            description: 'View your mailbox folder.',
        },
    },
    {
        path: '/mailbox/readletter/:mailId',
        component: Mailbox,
        seo: {
            title: 'Read Mail',
            description: 'Read a specific email.',
        },
    },
    {
        path: '/saved-donors',
        component: SavedDonorsPage,
        seo: {
            title: 'Saved Donors',
            description: 'View your saved donors.',
        },
    },
    {
        path: '/favourite-donors',
        component: LikedDonorsPage,
        seo: {
            title: 'Favourite Donors',
            description: 'View your favourite donors.',
        },
    },
    {
        path: '/compared-donors',
        component: ComparedDonorsPage,
        seo: {
            title: 'Compared Donors',
            description: 'Compare donors side by side.',
        },
    },
    {
        path: '/settings',
        component: SettingsPage,
        seo: {
            title: 'Settings',
            description: 'Change your settings.',
        },
    },
    {
        path: '/settings/:path',
        component: SettingsPage,
        seo: {
            title: 'Surrogacy Platform - Settings',
            description: 'Change specific settings.',
        },
    },
    {
        path: '/profile',
        component: ClientProfilePage,
        seo: {
            title: 'Profile',
            description: 'View your profile.',
        },
    },
    {
        path: '/coins',
        component: PackagesPage,
        seo: {
            title: 'Communication Services | Find Egg Donors and Surrogate Mother',
            description: 'Surrogacy platform - a service with an open database of egg donors and surrogate mothers. List of egg donors and surrogate mothers with pictures.',
        },
    },
    {
        path: '/packages',
        component: DonorProgramsPage,
        seo: {
            title: 'How much does Surrogate Mother services cost in Cyprus?',
            description: 'What is the price for a Surrogate Mother and Egg Donation service in North Cyprus? Full price list for Surrogacy and Egg Donation from Surrogacy Platform.',
        },
    },
    {
        path: '/packages/:program',
        component: SingleProgramPage,
        seo: {
            title: 'Egg Donation and IVF Programs. Serveces and Costs',
            description: 'Egg donation and surrogacy programs on a turnkey basis in the IVF clinic in Cyprus. Read the Program Stages, Agreement and Prices on Surrogacy Platform',
        },
    },
    {
        path: '/blog',
        component: BlogPage,
        seo: {
            title: 'Surrogate motherhood and Egg Donation Blog',
            description: 'Blog about Surrogacy Motherhood and Egg Donation in Cyprus from the Surrogacy Platform company.',
        },
    },
    {
        path: '/blog/page/:pageNumber',
        component: BlogPage,
        seo: {
            title: 'Surrogate motherhood and Egg Donation Blog',
            description: 'Blog about Surrogacy Motherhood and Egg Donation in Cyprus from the Surrogacy Platform company.',
        },
    },
    {
        path: '/blog/:slug',
        component: SingleBlogPage,
        seo: {
            title: 'Are Genes Passed on from the Surrogate Mother to the Child?',
            description: 'Do the genes of the surrogate mother affect the fetus? How does surrogacy affect the child? Read in our Blog',
        },
    },
    // {
    //     path: '/blog/what-will-the-baby-look-like-when-using-donor-oocytes',
    //     component: Post1,
    //     seo: {
    //         title: 'What Will the Baby Look Like When Using Donor Oocytes?',
    //         description: 'A child from an oocyte donor. Who will your future child look like, how to choose the right donor and whose genes will the child have?',
    //     },
    // },
    // {
    //     path: '/blog/surrogacy-and-egg-donation',
    //     component: Post3,
    //     seo: {
    //         title: 'How to Choose a Donor and Organize a Surrogacy Program?',
    //         description: 'You can find out more on our blog or talk to real Egg Donors and Surrogate Mothers on the Surrogacy Platform',
    //     },
    // },
    {
        path: '/privacy-policy',
        component: PrivacyPolicyPage,
        seo: {
            title: 'Surrogacy Platform - Privacy Policy',
            description: 'Read our privacy policy.',
        },
    },
    {
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        seo: {
            title: 'Surrogacy Platform - Terms and Conditions',
            description: 'Read the terms and conditions.',
        },
    },
    {
        path: '/trips-to-clinic',
        component: TripsToClinicPage,
        seo: {
            title: 'Trips to Best IVF and Fertility Clinic in Cyprus',
            description: 'All about traveling to our fertility clinic in Cyprus and signing a contract. Surrogacy and egg donation program at the clinic in Cyprus',
        },
    },
    {
        path: '/egg-donor-trips',
        component: EggDonorsTripsPage,
        seo: {
            title: 'When should the Egg Donor arrive at the IVF Clinic and how',
            description: 'When should an egg donor come to the clinic and how does this happen? Surrogacy Platform Blog',
        },
    },
    {
        path: '/surrogate-trips',
        component: SurrogateTripsPage,
        seo: {
            title: 'When the Surrogate Mother arrive to the IVF Clinic',
            description: 'When should a Surrogate Mother come to the clinic and how does this happen? Surrogacy Platform Blog',
        },
    },
    {
        path: '/trip-to-childbirth',
        component: TripToChildbirthPage,
        seo: {
            title: 'When can parents come to the birth of a surrogate mother',
            description: 'All about traveling to our fertility clinic in Cyprus and signing a contract. Surrogacy and egg donation programme at the clinic in Cyprus',
        },
    },
    {
        path: '/about-us',
        component: AboutUsPage,
        seo: {
            title: 'Egg Donation and Surrogacy services on Cyprus. Read more',
            description: 'Surrogacy platform - a service with an open database of egg donors and implementation of full Surrogacy Programmes in Cyprus. List of egg donors with pictures and surrogacy services',
        },
    },
    {
        path: '/contact-us',
        component: ContactUsPage,
        seo: {
            title: 'Contacts | Surrogacy Platform: Find Egg Donors and Surrogacy services',
            description: 'Surrogacy platform - a service with an open database of egg donors and implementation of full Surrogacy Programmes in Cyprus. List of egg donors with pictures and surrogacy services',
        },
    },
    {
        path: '/quick-start',
        component: QuickStart,
        seo: {
            title: 'Egg Donors and Surrogacy Free Database. Start Chat Now!',
            description: 'How to find an egg donor and surrogate mother? How can you see Egg Donor Pictures? How do you can speak with donors? We will tell you all this in our Tutorial',
        },
    },
    {
        path: '/program-stages',
        component: ProgramStagesPage,
        seo: {
            title: 'Surrogasy Programs. Read about Services and Stages',
            description: 'A step-by-step breakdown of the Surrogacy Programme. All about Preparation of documents, Medical examination, stages of Payment and childbirth will be explained by Surrogacy Platform',
        },
    },
    {
        path: '/sperm-donor-request',
        component: DonorRequestPage,
        props: {
            type:"sperm"
        },
        seo: {
            title: 'Become an Sperm Donor. Registration',
            description: 'How to become an Sperm Donor? Registration for egg donation at Surrogacy Platform',
        },
    },
    {
        path: '/egg-donor-request',
        component: DonorRequestPage,
        props: {
            type:"eggs"
        },
        seo: {
            title: 'Become an Egg Donor. Registration',
            description: 'How to become an Egg Donor? Registration for egg donation at Surrogacy Platform',
        },
    },
    {
        path: '/surrogate-mother-request',
        component: DonorRequestPage,
        props: {
            type:"surrogacy"
        },
        seo: {
            title: 'Become an Surrogate Mother. Registration',
            description: 'How to become an Surrogate Mother? Registration for egg donation at Surrogacy Platform',
        },
    },
    {
        path: '/full-registration/step1',
        component: DonorRegistrationPage,
        seo: {
            title: 'Surrogacy Platform - Registration Step 1',
            description: 'Complete the first step of registration.',
        },
    },
    {
        path: '/full-registration/step2',
        component: DonorRegistrationPage,
        seo: {
            title: 'Surrogacy Platform - Registration Step 2',
            description: 'Complete the second step of registration.',
        },
    },
    {
        path: '/full-registration/step3',
        component: DonorRegistrationPage,
        seo: {
            title: 'Surrogacy Platform - Registration Step 3',
            description: 'Complete the third step of registration.',
        },
    },
    {
        path: '/full-registration/step4',
        component: DonorRegistrationPage,
        seo: {
            title: 'Registration Step 4',
            description: 'Complete the fourth step of registration.',
        },
    },
    {
        path: '/legislation',
        component: LegislationPage,
        seo: {
            title: 'Surrogacy Platform - Legislation',
            description: 'Learn about legislation related to surrogacy and egg donation.',
        },
    },
    {
        path: '*',
        component: NotFoundPage,
        seo: {
            title: '404 - Page Not Found',
            description: 'The page you are looking for does not exist.',
        },
    },
];