import { GET_BLOG_ARTICLES_FAILURE, GET_BLOG_ARTICLES_REQUEST, GET_BLOG_ARTICLES_SUCCESS, GET_BLOG_ARTICLE_BY_ID_FAILURE, GET_BLOG_ARTICLE_BY_ID_REQUEST, GET_BLOG_ARTICLE_BY_ID_SUCCESS, SET_BLOG_CATEGORY } from "../constants/actionTypes";


const initialState = {
    loading: false,
    error: null,
    articles: null,
    articleById: null,
    blogCategory: "all"
};

const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BLOG_ARTICLES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_BLOG_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: action.payload,
                loading: false,
                error: null,
            };
        case GET_BLOG_ARTICLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_BLOG_ARTICLE_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_BLOG_ARTICLE_BY_ID_SUCCESS:
            return {
                ...state,
                articleById: action.payload,
                loading: false,
                error: null,
            };
        case GET_BLOG_ARTICLE_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_BLOG_CATEGORY:
            return {
                ...state,
                loading: false,
                error: null,
                blogCategory: action.payload
            };
        default:
            return state;
    }
};

export default blogReducer;
