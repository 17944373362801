export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const FETCH_DONORS_REQUEST = 'FETCH_DONORS_REQUEST';
export const FETCH_DONORS_SUCCESS = 'FETCH_DONORS_SUCCESS';
export const FETCH_DONORS_FAILURE = 'FETCH_DONORS_FAILURE';

export const FETCH_LIKED_DONORS_REQUEST = 'FETCH_LIKED_DONORS_REQUEST';
export const FETCH_LIKED_DONORS_SUCCESS = 'FETCH_LIKED_DONORS_SUCCESS';
export const FETCH_LIKED_DONORS_FAILURE = 'FETCH_LIKED_DONORS_FAILURE';

export const FETCH_SAVED_DONORS_REQUEST = 'FETCH_SAVED_DONORS_REQUEST';
export const FETCH_SAVED_DONORS_SUCCESS = 'FETCH_SAVED_DONORS_SUCCESS';
export const FETCH_SAVED_DONORS_FAILURE = 'FETCH_SAVED_DONORS_FAILURE';

export const GET_DONOR_PHOTO_REQUEST = 'GET_DONOR_PHOTO_REQUEST';
export const GET_DONOR_PHOTO_SUCCESS = 'GET_DONOR_PHOTO_SUCCESS';
export const GET_DONOR_PHOTO_FAILURE = 'GET_DONOR_PHOTO_FAILURE';

export const GET_DONOR_VIDEO_REQUEST = 'GET_DONOR_VIDEO_REQUEST';
export const GET_DONOR_VIDEO_SUCCESS = 'GET_DONOR_VIDEO_SUCCESS';
export const GET_DONOR_VIDEO_FAILURE = 'GET_DONOR_VIDEO_FAILURE';

export const BUY_DONOR_PHOTO_REQUEST = 'BUY_DONOR_PHOTO_REQUEST';
export const BUY_DONOR_PHOTO_SUCCESS = 'BUY_DONOR_PHOTO_SUCCESS';
export const BUY_DONOR_PHOTO_FAILURE = 'BUY_DONOR_PHOTO_FAILURE';

export const SET_FILTER_RESPONSE = 'SET_FILTER_RESPONSE';
export const SET_SURROGACY_DONORS = 'SET_SURROGACY_DONORS';
export const SET_SPERM_DONORS = 'SET_SPERM_DONORS';

export const SUBMIT_CONTACT_FORM_REQUEST = 'SUBMIT_CONTACT_FORM_REQUEST';
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
export const SUBMIT_CONTACT_FORM_FAILURE = 'SUBMIT_CONTACT_FORM_FAILURE';

export const FETCH_DONOR_BY_ID_REQUEST = 'FETCH_DONOR_BY_ID_REQUEST';
export const FETCH_DONOR_BY_ID_SUCCESS = 'FETCH_DONOR_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';

export const FETCH_USER_FAILURE = 'FETCH_USER_BY_ID_FAILURE';
export const FETCH_USER_BY_ID_REQUEST = 'FETCH_USER_BY_ID_REQUEST';
export const FETCH_DONOR_BY_ID_FAILURE = 'FETCH_DONOR_BY_ID_FAILURE';

export const FETCH_NEW_DONORS_REQUEST = 'FETCH_NEW_DONORS_REQUEST';
export const FETCH_NEW_DONORS_SUCCESS = 'FETCH_NEW_DONORS_SUCCESS';
export const FETCH_NEW_DONORS_FAILURE = 'FETCH_NEW_DONORS_FAILURE';
export const FETCH_DEFAULT_USER = 'FETCH_DEFAULT_USER';

export const ADD_DONOR_TO_SAVED_REQUEST = 'ADD_DONOR_TO_SAVED_REQUEST';
export const ADD_DONOR_TO_SAVED_SUCCESS = 'ADD_DONOR_TO_SAVED_SUCCESS';
export const ADD_DONOR_TO_SAVED_FAILURE = 'ADD_DONOR_TO_SAVED_FAILURE';

export const GET_SAVED_DONORS_REQUEST = 'GET_SAVED_DONORS_REQUEST';
export const GET_SAVED_DONORS_SUCCESS = 'GET_SAVED_DONORS_SUCCESS';
export const GET_SAVED_DONORS_FAILURE = 'GET_SAVED_DONORS_FAILURE';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';

export const FETCH_CHATS_REQUEST = 'FETCH_CHATS_REQUEST';
export const FETCH_CHATS_SUCCESS = 'FETCH_CHATS_SUCCESS';
export const FETCH_CHATS_FAILURE = 'FETCH_CHATS_FAILURE';

export const FETCH_SUPPORT_CHATS_REQUEST = 'FETCH_SUPPORT_CHATS_REQUEST';
export const FETCH_SUPPORT_CHATS_SUCCESS = 'FETCH_SUPPORT_CHATS_SUCCESS';
export const FETCH_SUPPORT_CHATS_ID_SUCCESS = 'FETCH_SUPPORT_CHATS_ID_SUCCESS';
export const FETCH_SUPPORT_CHATS_FAILURE = 'FETCH_SUPPORT_CHATS_FAILURE';

export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

export const RESET_MESSAGES = 'RESET_MESSAGES';

// Action Types
export const FETCH_TRANSLATOR_EMAILS_REQUEST = 'FETCH_TRANSLATOR_EMAILS_REQUEST';
export const FETCH_TRANSLATOR_EMAILS_SUCCESS = 'FETCH_TRANSLATOR_EMAILS_SUCCESS';
export const FETCH_TRANSLATOR_EMAILS_FAILURE = 'FETCH_TRANSLATOR_EMAILS_FAILURE';


export const FETCH_MESSAGES_FOR_EMAIL_REQUEST = 'FETCH_MESSAGES_FOR_EMAIL_REQUEST';
export const FETCH_MESSAGES_FOR_EMAIL_SUCCESS = 'FETCH_MESSAGES_FOR_EMAIL_SUCCESS';
export const FETCH_MESSAGES_FOR_EMAIL_FAILURE = 'FETCH_MESSAGES_FOR_EMAIL_FAILURE';

export const FETCH_EMAILS_FAILURE = 'FETCH_EMAILS_FAILURE';
export const FETCH_EMAILS_REQUEST = 'FETCH_EMAILS_REQUEST';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';

export const FETCH_STAR_REQUEST = 'FETCH_STAR_REQUEST';
export const FETCH_STAR_SUCCESS = 'FETCH_STAR_SUCCESS';
export const FETCH_STAR_FAILURE = 'FETCH_STAR_FAILURE';

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAILURE = 'SEND_MAIL_FAILURE';

export const UPDATE_EMAIL_NOTIFICATION_REQUEST = 'UPDATE_EMAIL_NOTIFICATION_REQUEST';
export const UPDATE_EMAIL_NOTIFICATION_SUCCESS = 'UPDATE_EMAIL_NOTIFICATION_SUCCESS';
export const UPDATE_EMAIL_NOTIFICATION_FAILURE = 'UPDATE_EMAIL_NOTIFICATION_FAILURE';

export const SELECT_DONOR_FOR_EMAIL = 'SELECT_DONOR_FOR_EMAIL';

export const SEND_CONVERSATION_REQUEST = 'SEND_CONVERSATION_REQUEST';
export const RECEIVE_CONVERSATION_RESPONSE = 'RECEIVE_CONVERSATION_RESPONSE';
export const CONVERSATION_ERROR = 'CONVERSATION_ERROR';

export const GET_EMAILS_SENT_REQUEST = 'GET_EMAILS_SENT_REQUEST';
export const GET_EMAILS_SENT_SUCCESS = 'GET_EMAILS_SENT_SUCCESS';
export const GET_EMAILS_SENT_FAILURE = 'GET_EMAILS_SENT_FAILURE';

export const DELETE_CONVERSATION_REQUEST = 'DELETE_CONVERSATION_REQUEST';
export const DELETE_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS';
export const DELETE_CONVERSATION_FAILURE = 'DELETE_CONVERSATION_FAILURE';

export const ADD_EMAIL_LABEL_SUCCESS = 'ADD_EMAIL_LABEL_SUCCESS';
export const ADD_EMAIL_LABEL_FAILURE = 'ADD_EMAIL_LABEL_FAILURE';
export const ADD_EMAIL_LABEL_REQUEST = 'ADD_EMAIL_LABEL_REQUEST';

export const GET_EMAILS_STARRED_REQUEST = 'GET_EMAILS_STARRED_REQUEST';
export const GET_EMAILS_STARRED_SUCCESS = 'GET_EMAILS_STARRED_SUCCESS';
export const GET_EMAILS_STARRED_FAILURE = 'GET_EMAILS_STARRED_FAILURE';

export const GET_EMAILS_DELETED_REQUEST = 'GET_EMAILS_DELETED_REQUEST';
export const GET_EMAILS_DELETED_SUCCESS = 'GET_EMAILS_DELETED_SUCCESS';
export const GET_EMAILS_DELETED_FAILURE = 'GET_EMAILS_DELETED_FAILURE';

export const DELETE_EMAILS_LABELS_REQUEST = 'DELETE_EMAILS_LABELS_REQUEST';
export const DELETE_EMAILS_LABELS_SUCCESS = 'DELETE_EMAILS_LABELS_SUCCESS';
export const DELETE_EMAILS_LABELS_FAILURE = 'DELETE_EMAILS_LABELS_FAILURE';

export const HIDE_MENU = 'HIDE_MENU';
export const SHOW_MENU = 'SHOW_MENU';

export const SOCKET_CONNECTION = 'SOCKET_CONNECTION';

export const FETCH_ONLINE_DONORS_REQUEST = 'FETCH_ONLINE_DONORS_REQUEST';
export const FETCH_ONLINE_DONORS_SUCCESS = 'FETCH_ONLINE_DONORS_SUCCESS';
export const FETCH_ONLINE_DONORS_FAILURE = 'FETCH_ONLINE_DONORS_FAILURE';

export const VIDEO_CONSUMER_REQUEST = 'VIDEO_CONSUMER_REQUEST';
export const VIDEO_CONSUMER_SUCCESS = 'VIDEO_CONSUMER_SUCCESS';
export const VIDEO_CONSUMER_FAILURE = 'VIDEO_CONSUMER_FAILURE';

export const SET_CHAT_CONNECTION = 'SET_CHAT_CONNECTION';
export const SET_CHAT_ACTIVE = 'SET_CHAT_ACTIVE';
export const SET_AVAILABLE_SECONDS = 'SET_AVAILABLE_SECONDS';


export const GET_CHAT_TIME_LEFT_REQUEST = 'GET_CHAT_TIME_LEFT_REQUEST';
export const GET_CHAT_TIME_LEFT_SUCCESS = 'GET_CHAT_TIME_LEFT_SUCCESS';
export const GET_CHAT_TIME_LEFT_FAILURE = 'GET_CHAT_TIME_LEFT_FAILURE';

export const GET_COINS_PACKAGES_REQUEST = 'GET_COINS_PACKAGES_REQUEST';
export const GET_COINS_PACKAGES_SUCCESS = 'GET_COINS_PACKAGES_SUCCESS';
export const GET_COINS_PACKAGES_FAILURE = 'GET_COINS_PACKAGES_FAILURE';

export const GET_DONOR_PACKAGES_REQUEST = 'GET_DONOR_PACKAGES_REQUEST';
export const GET_DONOR_PACKAGES_SUCCESS = 'GET_DONOR_PACKAGES_SUCCESS';
export const GET_DONOR_PACKAGES_FAILURE = 'GET_DONOR_PACKAGES_FAILURE';

export const GET_CLIENT_ORDER_DONOR_REQUEST = 'GET_CLIENT_ORDER_DONOR_REQUEST';
export const GET_CLIENT_ORDER_DONOR_SUCCESS = 'GET_CLIENT_ORDER_DONOR_SUCCESS';
export const GET_CLIENT_ORDER_DONOR_FAILURE = 'GET_CLIENT_ORDER_DONOR_FAILURE';

export const GET_LAST_ORDER_COINS_REQUEST = 'GET_LAST_ORDER_COINS_REQUEST';
export const GET_LAST_ORDER_COINS_SUCCESS = 'GET_LAST_ORDER_COINS_SUCCESS';
export const GET_LAST_ORDER_COINS_FAILURE = 'GET_LAST_ORDER_COINS_FAILURE';

export const SEND_PAYMENTS_REQUEST = 'SEND_PAYMENTS_REQUEST';
export const SEND_PAYMENTS_SUCCESS = 'SEND_PAYMENTS_SUCCESS';
export const SEND_PAYMENTS_FAILURE = 'SEND_PAYMENTS_FAILURE';

export const SEND_ORDER_DONOR_REQUEST = 'SEND_ORDER_DONOR_REQUEST';
export const SEND_ORDER_DONOR_SUCCESS = 'SEND_ORDER_DONOR_SUCCESS';
export const SEND_ORDER_DONOR_FAILURE = 'SEND_ORDER_DONOR_FAILURE';

export const FETCH_MODAL_ACTIVE = "FETCH_MODAL_ACTIVE";
export const FETCH_MODAL_CLOSE = "FETCH_MODAL_CLOSE";

export const SET_IS_SHOW_MODAL = "SET_IS_SHOW_MODAL";
export const FETCH_POPUP = "FETCH_POPUP";

export const UPLOAD_USER_AVATAR_REQUEST = 'UPLOAD_USER_AVATAR_REQUEST';
export const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS';
export const UPLOAD_USER_AVATAR_FAILURE = 'UPLOAD_USER_AVATAR_FAILURE';

export const DONOR_APPLICATION_REQUEST = 'DONOR_APPLICATION_REQUEST';
export const DONOR_APPLICATION_SUCCESS = 'DONOR_APPLICATION_SUCCESS';
export const DONOR_APPLICATION_FAILURE = 'DONOR_APPLICATION_FAILURE';

export const DONOR_FULL_REGISTRATION_REQUEST = 'DONOR_FULL_REGISTRATION_REQUEST';
export const DONOR_FULL_REGISTRATION_SUCCESS = 'DONOR_FULL_REGISTRATION_SUCCESS';
export const DONOR_FULL_REGISTRATION_FAILURE = 'DONOR_FULL_REGISTRATION_FAILURE';

export const UPLOAD_DONOR_PHOTOS_REQUEST = 'UPLOAD_DONOR_PHOTOS_REQUEST';
export const UPLOAD_DONOR_PHOTOS_SUCCESS = 'UPLOAD_DONOR_PHOTOS_SUCCESS';
export const UPLOAD_DONOR_PHOTOS_FAILURE = 'UPLOAD_DONOR_PHOTOS_FAILURE';

export const GET_CLIENT_BALANCE_REQUEST = 'GET_CLIENT_BALANCE_REQUEST';
export const GET_CLIENT_BALANCE_SUCCESS = 'GET_CLIENT_BALANCE_SUCCESS';
export const GET_CLIENT_BALANCE_FAILURE = 'GET_CLIENT_BALANCE_FAILURE';

export const GET_USER_UNREADED_MESS_REQUEST = 'GET_USER_UNREADED_MESS_REQUEST';

export const GET_BLOG_ARTICLES_REQUEST = 'GET_BLOG_ARTICLES_REQUEST';
export const GET_BLOG_ARTICLES_SUCCESS = 'GET_BLOG_ARTICLES_SUCCESS';
export const GET_BLOG_ARTICLES_FAILURE = 'GET_BLOG_ARTICLES_FAILURE';

export const GET_BLOG_ARTICLE_BY_ID_REQUEST = 'GET_BLOG_ARTICLE_BY_ID_REQUEST';
export const GET_BLOG_ARTICLE_BY_ID_SUCCESS = 'GET_BLOG_ARTICLE_BY_ID_SUCCESS';
export const GET_BLOG_ARTICLE_BY_ID_FAILURE = 'GET_BLOG_ARTICLE_BY_ID_FAILURE';

export const SET_BLOG_CATEGORY = 'SET_BLOG_CATEGORY';
