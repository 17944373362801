import { combineReducers } from 'redux';
import authReducer from './authReducer';
import donorReducer from './donorReducer';
import userReducer from './userReducers';
import textChatReducer from './textChatReducer';
import emailsReducer from "./emailReducer";
import globalReducer from './globalReducer';
import videoChatReducer from './videoChatReducer';
import ModalReducer from './modalReducer';
import blogReducer from './blogReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  donors: donorReducer,
  user: userReducer,
  textChats: textChatReducer,
  emails:emailsReducer,
  globalState: globalReducer,
  videoChat: videoChatReducer,
  modal: ModalReducer,
  blog: blogReducer
});

export default rootReducer;
