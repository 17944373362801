import { put, takeLatest, call } from 'redux-saga/effects';
import api from '../api/api';
import instance from '../api/api';
import { getBlogArticleByIdFailure, getBlogArticleByIdSuccess, getBlogArticlesFailure, getBlogArticlesSuccess } from "../actions/blogActions";
import { GET_BLOG_ARTICLES_REQUEST, GET_BLOG_ARTICLE_BY_ID_REQUEST } from '../constants/actionTypes';

function* getBlogArticles(action) {
    try {
        const { page, limit, category } = action.payload;
        let queryParams = `?page=${page}&limit=${limit}`;

        if (category) {
            queryParams += `&category=${JSON.stringify(category)}`;
        }

        const endpoint = '/admin/articles';
        const query = queryParams;
        const response = yield call(instance.get, `${endpoint}${query}`);

        function sortByDateDesc(dataArray) {
            return dataArray.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB - dateA;
            });
          }
          
          const sortedData = sortByDateDesc(response.data.data);

        yield put(getBlogArticlesSuccess({data: sortedData, total: response.data.total}));
    } catch (error) {
        yield put(getBlogArticlesFailure(error));
    }
}

function* getBlogArticleById(action) {
    const id = action.payload
    try {
        const response = yield call(instance.get, `/admin/article/${id}`);

        yield put(getBlogArticleByIdSuccess(response.data));
    } catch (error) {
        yield put(getBlogArticleByIdFailure(error));
    }
}

function* blogSaga() {
    yield takeLatest(GET_BLOG_ARTICLES_REQUEST, getBlogArticles);
    yield takeLatest(GET_BLOG_ARTICLE_BY_ID_REQUEST, getBlogArticleById);
}
  
export default blogSaga;