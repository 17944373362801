import { GET_BLOG_ARTICLES_FAILURE, GET_BLOG_ARTICLES_REQUEST, GET_BLOG_ARTICLES_SUCCESS, GET_BLOG_ARTICLE_BY_ID_FAILURE, GET_BLOG_ARTICLE_BY_ID_REQUEST, GET_BLOG_ARTICLE_BY_ID_SUCCESS, SET_BLOG_CATEGORY } from "../constants/actionTypes";

export const getBlogArticlesRequest = (page, limit, category) => ({
    type: GET_BLOG_ARTICLES_REQUEST,
    payload: {page, limit, category}
  });
  
  export const getBlogArticlesSuccess = (data) => ({
    type: GET_BLOG_ARTICLES_SUCCESS,
    payload: data,
  });
  
  export const getBlogArticlesFailure = (error) => ({
    type: GET_BLOG_ARTICLES_FAILURE,
    payload: error,
  });


export const getBlogArticleByIdRequest = (id) => ({
    type: GET_BLOG_ARTICLE_BY_ID_REQUEST,
    payload: id
  });
  
  export const getBlogArticleByIdSuccess = (data) => ({
    type: GET_BLOG_ARTICLE_BY_ID_SUCCESS,
    payload: data,
  });
  
  export const getBlogArticleByIdFailure = (error) => ({
    type: GET_BLOG_ARTICLE_BY_ID_FAILURE,
    payload: error,
  });

  export const setBlogCategory = (category) => ({
    type: SET_BLOG_CATEGORY,
    payload: category,
  });